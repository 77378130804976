<template>
  <div>
    <b-table
      id="ayList"
      striped
      responsive
      :fields="fields"
      :items="listAcademicYear.list"
      :per-page="perPage"
      :current-page="currentPage"
      show-empty
      :busy="listAcademicYear.loading"
    >
      <!-- Show table busy when loading -->
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle mr-1"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <!-- Custom header formatting -->
      <!-- <template #head()="scope">
              <div class="text-nowrap text-primary">
                {{ scope.column }}
              </div>
            </template> -->
      <!-- A virtual column -->
      <!-- <template #cell(index)="data">
        <div v-if="!data.item.isBlank">
          {{ data.index + 1 }}
        </div>
      </template> -->

      <!-- A custom formatted column -->
      <template #cell(startDay)="data">
        <div v-if="!data.item.isBlank">
          {{
            data.item.startDay +
            ", " +
            data.item.startMonth +
            " " +
            data.item.startYear
          }}
        </div>
      </template>

      <!-- A custom formatted column -->
      <template #cell(endDay)="data">
        <div v-if="!data.item.isBlank">
          {{
            data.item.endDay +
            ", " +
            data.item.endMonth +
            " " +
            data.item.endYear
          }}
        </div>
      </template>

      <template #cell(class)="data">
        <div v-if="!data.item.isBlank">
          <span pill :class="'text-' + statusVariant(data.item.status)">
            {{ data.value.length }}
          </span>
        </div>
      </template>

      <!-- A virtual composite column -->
      <template #cell(status)="data">
        <div v-if="!data.item.isBlank">
          <b-badge pill :variant="statusVariant(data.value)">
            <span v-if="data.value == 1">Running</span>
            <span v-if="data.value == 2">Stopped</span>
            <span v-if="data.value == -1">Cancelled</span>
          </b-badge>
        </div>
      </template>

      <!-- Optional default data cell scoped slot -->
      <template #cell(createdAt)="data">
        <div v-if="!data.item.isBlank">
          {{ dayjs(data.value).format("MMMM D, YYYY h:mm A") }}
        </div>
      </template>
    </b-table>
    <div class="d-block pt-1">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="sm"
        class="my-0"
        aria-controls="feesList"
      />
    </div>
  </div>
</template>

<script>
// TODO: Add Invoice references
import { BTable, BProgress, BBadge } from "bootstrap-vue";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
} from "@vue/composition-api";
import * as dayjs from "dayjs";
import API from "./api";

export default defineComponent({
  components: {
    BTable,
    BProgress,
    BBadge,
  },
  props: {
    dataList: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const isBusy = ref(true);
    const fields = reactive([
      // A virtual column that doesn't exist in dataList
      // "index",
      // A column that needs custom formatting
      { key: "startDay", label: "Re-opening Date" },
      // A regular column
      { key: "endDay", label: "Closing date" },
      // A regular column
      { key: "class", label: "Number of Classes" },
      // A virtual column made up from two fields
      { key: "status", label: "Status" },
      { key: "createdAt", label: "Date Created" },
    ]);
    const perPage = ref(10);
    const currentPage = ref(1);
    // console.log(console.log(JSON.stringify(props, undefined, 2)))
    const totalRows = computed(() => props.dataList.list.length);

    const listAcademicYear = computed(() => props.dataList);

    const statusVariant = (value) => {
      if (value == 1) {
        return "success";
      }

      if (value == 2) {
        return "secondary";
      }

      if (value == -1) {
        return "danger";
      }
    };

    return {
      isBusy,
      fields,
      perPage,
      currentPage,
      totalRows,
      statusVariant,
      dayjs,
      listAcademicYear,
    };
  },
});
</script>
<style scoped>
</style>