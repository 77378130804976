<template>
  <div>
    <!-- <b-card no-body>
      <b-card-body>
        <b-row align-h="center">
          <b-col>
            <label>Start and End Dates</label>
            <flat-pickr
              v-model="searchItemDateRange"
              class="form-control"
              :config="{ mode: 'range' }"
              name="searchItemDateRange"
            />
          </b-col>
          <b-col>
            <label>Status</label>
            <v-select
              v-model="searchItemStatus"
              :options="searchStatus"
              label="title"
            />
          </b-col>

          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>Student Name</label>
            <div class="d-flex align-items-center justify-content-end">
              <b-input-group>
                <b-form-input
                  v-model="searchItemName"
                  placeholder="eg: John Doe"
                />
              </b-input-group>
            </div>
          </b-col>
          <b-col>
            <label></label>
            <div>
              <b-button @click="searchQuery()" variant="primary"
                >Search</b-button
              >
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card> -->

    <b-card>
      <b-row>
        <b-col cols="12">
          <div class="d-flex justify-content-end pb-1">
            <b-button variant="outline-warning" @click="$router.push({ name: 'apps-academic-year-form', params: { defaultCycle: cycle }});">Create</b-button>
          </div>
        </b-col>
        <b-col cols="12">
          <academic-year-list :dataList="academicYear" />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { BTable, BProgress, BBadge } from "bootstrap-vue";
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch
} from "@vue/composition-api";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { useRouter } from "@/@core/utils/utils";
import AcademicYearList from "./AcademicYearList.vue";
import AcademicYearForm from "./AcademicsForm.vue";
import API from "./api"

export default defineComponent({
  components: {
    BTable,
    BProgress,
    BBadge,
    vSelect,
    flatPickr,
    AcademicYearList,
    AcademicYearForm
  },
  setup() {
    const { router } = useRouter();
    const searchItemDateRange = ref("");
    const searchItemStatus = ref("");
    const searchItemName = ref("");
    const searchStatus = reactive([
      {
        title: "Normal",
        code: 1,
      },
      {
        title: "Featured (Tier 1)",
        code: 100,
      },
      {
        title: "Featured (Tier 2)",
        code: 101,
      },
    ]);
    const cycle = ref([]);

    const completeTable = function(data = []) {
      if(data.length >= 10) {
        return data;
      }
      const boundary = 10 - data.length;
      for(let i = 0; i < boundary; i++) {
        data.push({});
      }
      return data;
    }
    const academicYear = reactive({
      list: [],
      total: 0,
      hasMore: false,
      loading: false
    });
    
    // Get all cycles
    const getDefaultCycles = async () => {
      const { data, success } = await API.getCycles();

      if (success) {
        cycle.value = data.cycles.list;
        // console.log(JSON.stringify(cycle.value, undefined, 2))
      }
    };

    // Add extra blank rows to fill the table    
    const paddRows = params => {
      const rowsDispenser = Math.max(0, params * 10 - academicYear.total);
      // console.log(rowsDispenser);

      for (let index = 0; index < rowsDispenser; index++) {
        academicYear.list.push({ isBlank: true });
        // console.log(JSON.stringify(this.academicYear.result, undefined, 2))
      }
      academicYear.loading = false
    };

    // Get list of academic year
    const getAcademicYearlist = async () => {
      academicYear.loading = true;
      const { data, success } = await API.getAllAcademicYears()

      if (success) {
        academicYear.list = data.academics.list
        academicYear.total = data.academics.total
        academicYear.hasMore = data.academics.hasMore
        paddRows(data.academics.list.length);
      }
    };

    const createForm = () => {
      router.push({
        name: 'apps-academic-year-form',
        params: { defaultCycle: cycle }
      });
    }

    // Save form if form is close abruptly or refreshed


    onMounted(() => {
      getAcademicYearlist()
      getDefaultCycles()
    })

    return {
      searchItemDateRange,
      searchItemStatus,
      searchItemName,
      searchStatus,
      academicYear,
      cycle,
      createForm
    };
  },
});
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style> 